
body {
  font-family: 'NanumSquare Neo';
  background-color: #1A1A1A;
  margin: 0;
}
@font-face {
  font-family: 'NanumSquare Neo';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/font/NanumSquareNeoTTF-aLt.woff2') format('woff2'),
       url('./assets/font/NanumSquareNeoTTF-aLt.woff') format('woff'),
       url('./assets/font/NanumSquareNeoTTF-aLt.ttf') format('truetype');
  font-display: swap;
} 
@font-face {
  font-family: 'NanumSquare Neo';
  font-weight: 400;
  font-style: normal;
  src: url('./assets/font/NanumSquareNeoTTF-bRg.woff2') format('woff2'),
       url('./assets/font/NanumSquareNeoTTF-bRg.woff') format('woff'),
       url('./assets/font/NanumSquareNeoTTF-bRg.ttf') format('truetype');
  font-display: swap;
} 
@font-face {
  font-family: 'NanumSquare Neo';
  font-weight: 700;
  font-style: normal;
  src: url('./assets/font/NanumSquareNeoTTF-cBd.woff2') format('woff2'),
       url('./assets/font/NanumSquareNeoTTF-cBd.woff') format('woff'),
       url('./assets/font/NanumSquareNeoTTF-cBd.ttf') format('truetype');
  font-display: swap;
} 
@font-face {
  font-family: 'NanumSquare Neo';
  font-weight: 800;
  font-style: normal;
  src: url('./assets/font/NanumSquareNeoTTF-dEb.woff2') format('woff2'),
       url('./assets/font/NanumSquareNeoTTF-dEb.woff') format('woff'),
       url('./assets/font/NanumSquareNeoTTF-dEb.ttf') format('truetype');
  font-display: swap;
} 
@font-face {
  font-family: 'NanumSquare Neo';
  font-weight: 900;
  font-style: normal;
  src: url('./assets/font/NanumSquareNeoTTF-eHv.woff2') format('woff2'),
       url('./assets/font/NanumSquareNeoTTF-eHv.woff') format('woff'),
       url('./assets/font/NanumSquareNeoTTF-eHv.ttf') format('truetype');
  font-display: swap;
} 